// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-coin-flip-tsx": () => import("./../src/pages/coin-flip.tsx" /* webpackChunkName: "component---src-pages-coin-flip-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-past-events-tsx": () => import("./../src/pages/past-events.tsx" /* webpackChunkName: "component---src-pages-past-events-tsx" */),
  "component---src-pages-rules-tsx": () => import("./../src/pages/rules.tsx" /* webpackChunkName: "component---src-pages-rules-tsx" */)
}

